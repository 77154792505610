<template lang="pug">
  v-container.pa-0(fluid)
    v-img(
      :src="require('@/assets/images/stop-banner.jpg')"
    )
</template>

<script>
export default {
  name: 'StopBanner',
};
</script>

<style scoped>

  .container {
    display: flex;
    align-items: center;
    background-color: #444444;
    height: 100%;
  }

</style>
